import React, { useState } from 'react';
import './index.css';
import Badge from "../../Images/ARVABadge.svg"

const RetailMoreInfo = () => {

  return (
    <div className='container p3 award'>
      <div className='bubble'>
        <p className='heading'>Where do I get one?</p>
        <p className='paragraph'>
        The world’s first Flexible Solar Awning, exclusively available for preorder at Award RV Superstore! Visit us in-store to see this innovative product that combines shade and solar power in one sleek design. Experience the future of outdoor living today at our only retailer location.
        </p>
        <button onClick={() => window.open('https://www.awardrv.com.au/luxury-awnings-flexible-solar-awning?preview=y', '_blank', 'noopener,noreferrer')} className=''>View on Award RV Superstore</button>
        <div className='award-cut'> </div>
      </div>
    </div>
  );
}

export default RetailMoreInfo;
