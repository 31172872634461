import React, { useState } from 'react';
import './index.css';
import Badge from "../../Images/ARVABadge.svg"

const ARVA = () => {

  return (
    <div className='container p3 arva'>
      <div className='two-col'>
        <div>
          <p className='heading'>Distributed By</p>
          <img src={Badge} alt="" />
        </div>
        <div>
          <p className='heading'>About Us</p>
          <p className='paragraph'>
            Australian RV stands at the forefront of the caravan and motorhome accessory market in Australia. As a leading wholesaler, we specialize in sourcing and distributing innovative RV products, catering to retailers, manufacturers, and service agents alike.
          </p>
          <p className='paragraph'>
            Our commitment goes beyond just supplying products; we aim to revolutionize the RV experience. From advanced water heating systems to products we didn't think were possible a couple of years ago, we ensure that our range includes only the best, setting new standards in quality and innovation for the industry.
          </p>
        </div>
      </div>
      <div className='bubble'>
        <p>For wholesale enquires please visit <a>austrv.com.au/solar</a></p>
        <button onClick={() => window.open('https://www.austrv.com.au/solar', '_blank')} className='arva-bg'>View on Australian RV</button>
      </div>
    </div>
  );
}

export default ARVA;
