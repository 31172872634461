import React, { useState, useEffect } from 'react';
import './index.css';
import Logo from "../../Images/LALogo.svg"
import MenuIcon from "../../Images/menu.svg"

const Header = ({ onNav }) => {
  const [isActive, setIsActive] = useState(false);
  const [isMNav, setMNav] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset;
    const threshold = window.innerHeight - 100; // Define your threshold value here

    // Set `isActive` based on the scroll position
    // For example, add the class if scrolled past 100px
    if (position > threshold) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`${isMNav ? 'nav-on' : ''} ${isActive ? 'nav-active' : ''}`}>
      <div className='left'>
        <img src={Logo} alt="Header Luxury Awnings Logo" />
      </div>
      <div className='right'>
        <div className='desktop'>
          <a onClick={(e) => {e.preventDefault(); onNav(1);}} href="">Features</a>
          {/* <a onClick={(e) => {e.preventDefault(); onNav(0);}} href="">Register Interest</a> */}
          <a onClick={(e) => {e.preventDefault(); onNav(1);}}href="">Specifications</a>
          <a onClick={(e) => {e.preventDefault(); onNav(3);}}href="">Gallery</a>
          <a onClick={(e) => {e.preventDefault(); onNav(4);}}href="">FAQ</a>
          <a onClick={(e) => {e.preventDefault(); onNav(2);}}href="">Contact</a>
        </div>
        <div className='mobile'>
          <img src={MenuIcon} onClick={ () => setMNav(!isMNav)} alt="" />
        </div>
        {isMNav && <div className='m-nav'>
          {/* <a onClick={(e) => {e.preventDefault(); onNav(0);}} href="">Register Interest</a> */}
          <a onClick={(e) => {e.preventDefault(); onNav(1);}} href="">Features</a>
          <a onClick={(e) => {e.preventDefault(); onNav(1);}}href="">Specifications</a>
          <a onClick={(e) => {e.preventDefault(); onNav(3);}}href="">Gallery</a>
          <a onClick={(e) => {e.preventDefault(); onNav(4);}}href="">FAQ</a>
          <a onClick={(e) => {e.preventDefault(); onNav(2);}}href="">Contact</a>
        </div>}
      </div>
    </nav>
  );
}

export default Header;
