import React, { useState } from 'react';
import './index.css';
import Logo from "../../Images/LogoSVG.svg"

const Footer = ({ onNav }) => {

  return (
    <div className='footer p3'>
      <img src={Logo} alt="" />
      <div className='footer-nav'>
        <a onClick={(e) => {e.preventDefault(); onNav(0);}} href="">Register Interest</a>
        <a onClick={(e) => {e.preventDefault(); onNav(1);}}href="">Specifications</a>
        <a onClick={(e) => {e.preventDefault(); onNav(3);}}href="">Gallery</a>
        <a onClick={(e) => {e.preventDefault(); onNav(2);}}href="">Contact</a>
      </div>
      <span>Copyright 2024 Australian RV Accessories</span>
    </div>
  );
}

export default Footer;
