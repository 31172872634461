import React, { useState } from 'react';
import './index.css';
import features from '../../Images/Features.svg';
import Img1 from '../../Images/Gallery/Frame4.png';
import Img6 from '../../Images/Gallery/NoPeopleEditSquare.jpg';
import Img2 from '../../Images/Gallery/Frame5.png';
import Img3 from '../../Images/Gallery/Layup.jpg';
import Img4 from '../../Images/Gallery/Frame7.png';
import Img5 from '../../Images/Gallery/Frame8.png';

const Gallery = () => {

  return (
    <div className='p3'>
      <p className='heading'>Image Gallery</p>
      <div className='img-gal'>
        <img src={Img6} alt="" />
        <img src={Img1} alt="" />
        <img src={Img2} alt="" />
        <img src={Img3} alt="" />
        <img src={Img4} alt="" />
        <img src={Img5} alt="" />
      </div>
    </div>
  );
}

export default Gallery;
