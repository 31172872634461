import React, { useState } from 'react';
import './index.css';
import LoadingModal from '../../LoadingModal';
import logo from '../../Images/WebsiteLogo.svg';
import arrow from '../../Images/chevron.svg';

const Splash = ({onNav}) => {

  return (
    <div className='container'>
      <div className="form-container background2">

        <img className='logo' src={logo} alt='Flexible Solar Awning Logo'></img >
        <button className='solar-gradient' style={{padding: "1rem 3rem"}} onClick={(e) => {e.preventDefault(); onNav(1);}}>Explore</button>
        <img className='more-arrow' src={arrow} alt='Explore More Arrow'></img >

       
      </div>
    </div>
  );
}

export default Splash;
