import React, { useEffect } from 'react';
import './index.css';
import Badge from "../../Images/ARVABadge.svg"

const Newsletter = () => {
  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = () => resolve(script);
        script.onerror = () => reject(new Error(`Failed to load script ${src}`));
        document.head.appendChild(script);
      });
    };

    const loadCss = (href) => {
      const link = document.createElement('link');
      link.href = href;
      link.rel = 'stylesheet';
      link.type = 'text/css';
      document.head.appendChild(link);
    };

    loadCss('//cdn-images.mailchimp.com/embedcode/classic-061523.css');
    loadScript('//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js').then(() => {
      (function ($) {
        window.fnames = new Array();
        window.ftypes = new Array();
        window.fnames[0] = 'EMAIL';
        window.ftypes[0] = 'email';
        window.fnames[1] = 'FNAME';
        window.ftypes[1] = 'text';
        window.fnames[2] = 'LNAME';
        window.ftypes[2] = 'text';
        window.fnames[3] = 'ADDRESS';
        window.ftypes[3] = 'address';
        window.fnames[4] = 'PHONE';
        window.ftypes[4] = 'phone';
      })(window.jQuery);
      var $mcj = window.jQuery.noConflict(true);
    }).catch((error) => {
      console.error(error);
    });
  }, []);

  return (
    <div className='container p3'>
      <div className='bubble'>
        <div id="mc_embed_shell">
          <div id="mc_embed_signup">
            <form
              action="https://awardrv.us12.list-manage.com/subscribe/post?u=7aa3a33fff60ff89cfc99416f&amp;id=7d978d3d3d&amp;f_id=0051afe1f0"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className="validate"
              target="_blank"
            >
              <div id="mc_embed_signup_scroll">
                <h2>Join Our Newsletter for the Latest Solar Awning Updates!</h2>
                <div className="indicates-required">
                  <span className="asterisk">*</span> indicates required
                </div>
                <div className="mc-field-group">
                  <label htmlFor="mce-EMAIL">
                    Email Address <span className="asterisk">*</span>
                  </label>
                  <input
                    type="email"
                    name="EMAIL"
                    className="required email"
                    id="mce-EMAIL"
                    required
                  />
                </div>
                <div className="mc-field-group">
                  <label htmlFor="mce-FNAME">First Name </label>
                  <input type="text" name="FNAME" className="text" id="mce-FNAME" />
                </div>
                <div hidden>
                  <input type="hidden" name="tags" value="134" />
                </div>
                <div id="mce-responses" className="clear">
                  <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
                  <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
                </div>
                <div
                  aria-hidden="true"
                  style={{ position: 'absolute', left: '-5000px' }}
                >
                  <input
                    type="text"
                    name="b_7aa3a33fff60ff89cfc99416f_7d978d3d3d"
                    tabIndex="-1"
                  />
                </div>
                <div className="clear">
                  <input
                    type="submit"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                    className="button solar-gradient"
                    value="Subscribe"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Newsletter;
