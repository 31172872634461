import React, { useState } from 'react';
import './index.css';
import features from '../../Images/Features.svg';
import Img1 from '../../Images/Gallery/NoPeopleEdit.jpg';

const Specs = () => {

  return (
    <div className='container p3'>
      <h1>Worlds First Flexible Solar Awning</h1>

      <p className='paragraph p3' style={{textAlign: "center"}}>Welcome to the future of off-grid living with our innovative world’s first solar awning. Featuring advanced HBC solar cells, it delivers unparalleled Efficiency, Durability, and Flexibility. Perfect for modern caravan and RV enthusiasts seeking sustainable energy.</p>

      <img className='hero-image' src={Img1} alt='Caravan With Awning extended'></img>
      <div className='gap'></div>
      <p className='heading'>Features</p>
      {/* <img className='features mb-3' src={features}></img > */}
      <div className='feature'>
        <div className='box'></div>
        <div>
          <p className='heading2'>Next Gen Performance</p>
          <p className='paragraph'>The solar awning delivers exceptional performance tailored to your needs. The 16ft version generates up to 1050 watts of power (87 amps @ 12v), providing ample energy for off-grid living. Smaller sizes also offer impressive power outputs, ensuring efficient energy production even in low-light conditions.
Built to endure harsh weather, the solar awning maintains peak performance, guaranteeing a consistent and reliable renewable energy source for your caravan or RV. With this high-performance solution, you can enjoy the freedom of off-grid living without compromising on power.</p>
        </div>
      </div>

      <div className='feature'>
        <div className='box'></div>
        <div>
          <p className='heading2'>HBC Solar Cell Technology</p>
          <p className='paragraph'>Our solar awning utilizes cutting-edge Heterojunction with Intrinsic Thin Layer Back Contact (HBC) solar cell technology. These advanced cells offer superior efficiency, converting more sunlight into usable energy compared to traditional solar cells.
HBC technology excels in partial shading, ensuring consistent power generation throughout the day. With a focus on durability and longevity, these cells provide a sustainable and reliable energy source for your off-grid adventures. Experience the benefits of advanced solar technology with our innovative awning.</p>
        </div>
      </div>

      <div className='feature'>
        <div className='box'></div>
        <div>
          <p className='heading2'>Supporting Components</p>
          <p className='paragraph'>The solar awning is equipped with top-notch supporting components to ensure seamless integration and optimal performance. It includes a Victron Energy Smart Solar MPPT charge controller that efficiently manages power flow and maximizes energy storage.

​

We hand picked this particular Solar Controller for this awning as it seamlessly integrates with existing Non-Victron installations and also perfectly communicates with existing Victron eco-systems in Recreational and Domestic applications. Whilst we recommend the Victron Energy Smart Solar MPPT, the Solar awning can easily integrate where existing Solar Controllers may be in place. Speak with our experts to confirm compatibility.

​

Pre fitted supports for anti-flap kits, enhances stability and reduces noise in windy conditions.
We designed our Solar Awning to be compatible with existing Anti-Flap kits already on the market, so no need to throw out your old hardware if you are upgrading your awning.

​

Designed for easy maintenance, the components are durable and reliable, ensuring that your solar awning operates smoothly and effectively.</p>
        </div>
      </div>
      
      <div className='feature'>
        <div className='box'></div>
        <div>
          <p className='heading2'>Easy Installation</p>
          <p className='paragraph'>Installing the solar awning is straightforward, designed for both DIY enthusiasts and professional installers. The Solar Panel is easy to handle and can be set up within a few hours with the provided detailed instructions (Two people required).

​

The universal design ensures compatibility with most caravan and RV models older or current, and our support team is available to assist with any specific requirements. Once installed, the awning seamlessly integrates with your existing setup, providing immediate benefits. Simplifying your silent power generation.</p>
        </div>
      </div>

      <div className='gap'></div>

      <p className='heading'>Specifications</p>

      <div className='table'>
        <div className='table-col small'>
          <p className='tHead'>Model</p>
          <p>Watts</p>
          <p>Voltage</p>
          <p>Peak Amperage</p>
          <p>Weight</p>
          <p>Awning Arm Height</p>
          <p>Awning Retracted Thickness</p>
          <p>Awning Extension</p>
          <p>Mounting Plates</p>
          <p>Motor</p>
          <p>Hardware Material</p>
          <p>Hardware Colour</p>
          <p>UV Resistance</p>
          <p>Light Strip</p>
          <p>Certification</p>
        </div>
        <div className='table-col'>
          <div><p className='tHead'>LA12850 12ft</p><p className='tHead'>LA161015 16ft</p></div>
          <div><p>850W</p><p>1050W</p></div>
          <div><p>105V</p><p>95V</p></div>
          <div><p>70A @ 12V</p><p>88A @ 12V</p></div>
          <div><p>63kg</p><p>77kg</p></div>
          <p>1.89m (6.2ft)</p>
          <p>14cm (5.5in)</p>
          <p>2.3m (7.55ft)</p>
          <p>Wall (Flat surface)</p>
          <p>DUYA motor DC 12V</p>
          <p>Heavy-duty aluminum</p>
          <p>Black / Silver</p>
          <p>100% UV blockage</p>
          <p>White & Insect Repellent Light</p>
          <p>CE/FCC/ROHS/C-TICK/PSE/UL/EMC</p>
        </div>
      </div>
    </div>
  );
}

export default Specs;
