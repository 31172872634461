import React, { useRef } from 'react';
import './App.css';
import LeadGen from './Sections/LeadGen'
import Specs from './Sections/Specs'
import Footer from './Sections/Footer'
import ARVA from './Sections/ARVA'
import Header from './Sections/Header'
import Gallery from './Sections/Gallery';
import RetailMoreInfo from './Sections/RetailMoreInfo';
import Splash from './Sections/Splash';
import Newsletter from './Sections/Newsletter';
import FAQ from './Sections/FAQ';

function App() {
  const NavHome = useRef(null);
  const NavSpecs = useRef(null);
  const NavGallery = useRef(null);
  const NavArva = useRef(null);
  const NavFaq = useRef(null);

  const scrollToMarker = (markerRef) => {
    markerRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const OnNav = (i) => {
    switch(i){
      case 0: scrollToMarker(NavHome); break;
      case 1: scrollToMarker(NavSpecs); break;
      case 2: scrollToMarker(NavArva); break;
      case 3: scrollToMarker(NavGallery); break;
      case 4: scrollToMarker(NavFaq); break;
      default: scrollToMarker(NavHome); break;
    }
  }

  return (
    <div className='main'>
      <Header onNav={OnNav}/>
      <div ref={NavHome} />
      {/* <LeadGen /> */}
      <Splash onNav={OnNav}/>
      <div className='wid-con'>
        <div ref={NavSpecs} />
        <Specs />
        <div ref={NavFaq} />
        <FAQ />
        <div ref={NavGallery} />
        <Gallery />
        <div ref={NavArva} />
        <RetailMoreInfo />
        <Newsletter />
        <ARVA />
      </div>
      <Footer onNav={OnNav} />
    </div>

  );
}

export default App;
