import React, { useState } from 'react';
import './index.css';
import fawjson from '../../faq.json';
import features from '../../Images/Features.svg';
import Img1 from '../../Images/Gallery/NoPeopleEdit.jpg';

const FAQ = () => {

  return (
    <div className='container p3'>
      <p className='heading'>FAQ</p>

      {fawjson.faqs.map((faq) => 
          <div className='feature'>
          <div className='box'></div>
              <div>
                <p className='heading2'>{faq.question}</p>
                <p className='paragraph'>{faq.answer}</p>
              </div>
          </div>
      )}
    </div>
  );
}

export default FAQ;
